import React from 'react'
import CountUp from 'react-countup'
import {useInView} from 'react-intersection-observer'
import {motion} from 'framer-motion'
import {fadeIn} from '../variants'
import { Link } from 'react-scroll'
const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  })
  return (
    <div id='about' className='section' ref={ref}>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen'>
          <motion.div
          variants={fadeIn('right',0.3)}
          initial='hidden'
          whileInView={'show'}
          viewport={{once: false, amount: 0.3}}
          className='flex-1 bg-about bg-contain bg-no-repeat h-[640px] mix-blend-lighten bg-top'></motion.div>
          <motion.div
          variants={fadeIn('left',0.5)}
          initial='hidden'
          whileInView={'show'}
          viewport={{once: false, amount: 0.3}}
          className='flex-1'>
            <h2 className='h2 text-amber-500'>About me.</h2>
            <h3 className='h3 mb-4'>I'm a Front-end Developer with over a year of experience.</h3>
            <p className='mb-6'>
              Spanish, English, and French speaker with two engineering degrees, in Computer Science and Computer Systems in  Hardware.
            </p>
            <div className='flex gap-x-6 lg:gap-x-10 mb-12'>
              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={2} duration={3} /> : null}
                </div>
                <div className='font-primary text-sm tracking-[2px]'>Years of Experience</div>
              </div>
              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={20} duration={3} /> : null}+
                </div>
                <div className='font-primary text-sm tracking-[2px]'>Projects completed</div>
              </div>
              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={100} duration={3} /> : null}+
                </div>
                <div className='font-primary text-sm tracking-[2px]'>Satisfied clients</div>
              </div>
            </div>
            <div className='flex gap-x-8 items-center'>
              <Link to='contact' smooth={true} spy={true} className='btn btn-sm flex items-center hover:cursor-pointer'>
                Contact me
              </Link>
              <Link to='work' smooth={true} spy={true} className='text-gradient btn-link hover:cursor-pointer'>My Portfolio</Link>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default About