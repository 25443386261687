import React from 'react'
import { BsArrowUpRight } from 'react-icons/bs'
import { motion } from 'framer-motion'
import { fadeIn } from '../variants'
import { Link } from 'react-scroll'
const services = [
  {
    name: 'Web Design',
    description: 'Design a complete personal website from scratch.',
    link: '#',
  },
  {
    name: 'Web Development',
    description: 'Develop a design of a complete website.',
    link: '#',
  },
  {
    name: 'App Development',
    description: 'Make your website into a mobile app.',
    link: '#',
  },
  {
    name: 'Full-stack Development',
    description: 'Develop front-end and back-end of your website.',
    link: '#',
  },
]

const Services = () => {
  return (
    <div className='section' id='services'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row'>
          <motion.div 
          variants={fadeIn('right',0.3)}
          initial='hidden'
          whileInView={'show'} viewport={{once: false, amount: 0.7}}
          className='flex-1 lg:bg-services lg:bg-bottom bg-no-repeat mix-blend-lighten mb-12 lg:mb-0'>
            <h2 className='h2 text-amber-500 mb-6'>What I Do.</h2>
            <h3 className='h3 max-w-[455px] mb-16'>I'm a Front-End Developer with over a year of experience. </h3>
            <Link to='work' smooth={true} spy={true} className='btn btn-sm flex items-center w-fit hover:cursor-pointer'>See my work</Link>
          </motion.div>
          <div className='flex-1 '>
            <div className=''>
              {services.map((service, index) => (
                <motion.div
                  key={index}
                  variants={fadeIn('left',0.2+index*0.2)}
                  initial='hidden'
                  whileInView={'show'} viewport={{once: false, amount: 0.7}}
                  className='border-b border-white/20 h-[146px] mb-[38px] flex justify-between rounded-lg'
                >
                  <div className='max-w-[476px]'>
                    <h4 className='text-[20´x] tracking-wider font-primary font-semibold mb-6'>{service.name}</h4>
                    <p className='font-secondary leading-tight'>{service.description}</p>
                  </div>
                  <div className='flex flex-col flex-1 items-end'>
                  <Link to='contact' smooth={true} spy={true} className='btn w-9 h-9 mb-[42px] flex justify-center items-center hover:cursor-pointer'><BsArrowUpRight/></Link>
                    <a href={service.link} className='text-gradient text-sm'>Learn more</a>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services