import React from 'react'
// images
import Logo from '../assets/logoAV.png'
import { Link } from 'react-scroll'

const Header = () => {
  return (
    <div className='py-8'>
      <div className='container mx-auto'>
        <div className='flex justify-between items-center'>
          <a href='#'>
            <img src={Logo} alt='logo' className='h-20'/>
          </a>
          <Link to='contact' smooth={true} spy={true} className='btn btn-sm flex items-center hover:cursor-pointer'>
            Work with me
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Header