import React from 'react'
import Image from '../assets/avatar.png'
import { FaGithub, FaLinkedin } from 'react-icons/fa'
import { TypeAnimation } from 'react-type-animation'
import {motion} from 'framer-motion'
import {fadeIn} from '../variants'
import { Link } from 'react-scroll'

const Banner = () => {
  return (
    <div className='min-h-[85vh] lg:min-h-[78vh] flex items-center' id='home'>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
          <div className='flex-1 text-center font-secondary lg:text-left'>
            <motion.h1 variants={fadeIn('up', 0.3)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='text-[55px] font-bold leading-[0.8] lg:text-[110px]'>
              ARNOLDO <span> VALDEZ </span>
            </motion.h1>
            <motion.div variants={fadeIn('up', 0.4)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]'>
              <span className='text-white mr-4'>I am a</span>
              <TypeAnimation sequence={[ 'Web Developer', 2000, 'Engineer', 2000, 'Freelancer', 2000 ]} speed={50} className='text-amber-500' wrapper='span' repeat={Infinity} />
            </motion.div>
            <motion.p variants={fadeIn('up', 0.5)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='mb-8 max-w-lg mx-auto lg:mx-0'>
            A versatile trilingual and double engineer full-stack web developer with a keen eye for front-end design. Committed to continuous self
            improvement, I thrive on staying up-to-date with the latest web technologies to deliver engaging and user
            friendly digital experiences. 
            </motion.p>
            <motion.div variants={fadeIn('up', 0.6)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0'>
            <Link to='contact' smooth={true} spy={true} className='btn btn-lg flex items-center hover:cursor-pointer'>
              Contact me
            </Link>
              <Link to='work' smooth={true} spy={true} className='text-gradient btn-link hover:cursor-pointer'>My Portfolio</Link>
            </motion.div>
            <motion.div variants={fadeIn('up', 0.7)} initial="hidden" whileInView={'show'} viewport={{once: false, amount: 0.7}} className='flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0'>
            <a href="https://github.com/arnoldodany44" target="_blank">
              <FaGithub />
            </a>
            <a href="https://www.linkedin.com/in/arnoldo-daniel-valdez-domínguez" target="_blank">
              <FaLinkedin />
            </a>
            </motion.div>
          </div>
          <motion.div variants={fadeIn('left', 0.5)} initial="hidden" whileInView={'show'}  className='hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px]'>
            <img src={Image} alt='avatar'/>
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default Banner